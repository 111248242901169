import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import { getIconName } from '../common/site/utils';

const ExploreFeature = ({amenitieslist}) => {
    return (
        <div className="explore-feature-wrapper">
            <Container>
                <Row>
                    <Col>
                        <h2>Or explore by feature...</h2>
                    </Col>
                </Row>
                <Row className="explore-feature-row">
                {amenitieslist.map(amenities => (
                    <Col lg={4} md={6} className="explore-feature-wrapper-inner">
                        <div className="explore-feature-list">
                            <Link to={amenities.Link} className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center icon-section">
                                    <i className={`icon icon-ammenity-${getIconName(amenities.Name)}`}></i> <span className="explore-text">{amenities.Name}</span>
                                </div>
                                <i className="icon icon-inspired-slider-link-arrow"></i>
                            </Link>
                        </div>
                    </Col>
                ))}            
                </Row>
            </Container>
        </div>
    )
}

export default ExploreFeature