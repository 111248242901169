import { Link } from "gatsby";
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from 'react-select';
import { AllNews, NewsCategories } from '../../queries/common_use_query';
import "./TeamsListing.scss";
import leftIcon from "../../images/icons/CaretLeft.svg"
import rightIcon from "../../images/icons/CaretRight.svg"
const postsPerPage = 12;
let arrayForHoldingPosts = [];

const NewsListing = () => {

  const { loading: loading, error: error, data: data } = NewsCategories();
  const { loading: news_loading, error: news_error, data: news_data } = AllNews();

  const [newscategory, setNewscat] = useState('');
  const [mobileonly, setMobileonly] = useState(false);
  const [listnews, setListNews] = useState([]);

  useEffect(() => {        
    setListNews(news_data && news_data.teams && news_data.teams)
  }, [news_data]);

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(9);

  useEffect(() => {
    arrayForHoldingPosts = [];
    if ($(window).width() < 767) {
      setMobileonly(true);
    }
  }, []);

  useEffect(() => {
      arrayForHoldingPosts = [];
      var filternews = news_data && news_data.blogs && news_data.blogs;
      if(newscategory) {
          setPostsToShow([]);
          setListNews(filternews.filter(obj => obj?.blogs_categories[0]?.id === newscategory))  
      } else {
          setListNews (filternews)
          setNext(12);
      }
  }, [newscategory, news_data]);

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + postsPerPage
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    setCurrentItems(listnews?.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(listnews?.length / postsPerPage))
  }, [itemOffset, postsPerPage, listnews])

  const handlePageClick = event => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * postsPerPage) % listnews?.length
    setItemOffset(newOffset)
  }

  // Select 
  var selectvalues = []
  selectvalues.push({ value:'', label: 'All News' } )
  {data && data.blogsCategories && data.blogsCategories.map(val =>selectvalues.push({ value:val.id, label: val.category } ))}    
  // Select 

  // Dropdown react select styles
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? "#161413" : null,
            color: isSelected ? "#ffffff" : "#161413",
        }
    },
    control: styles => ({
        ...styles,
        backgroundColor: null,
        border: 0,
        paddingLeft: 0,
        outline: 0,
        boxShadow: "none",
        color: "#161413",
        fontSize: "15px",
    }),
    valueContainer: styles => ({
        ...styles,
        fontSize: "15px",
        paddingLeft: 0,
        lineHeight: "21px",
        cursor: "pointer",
    }),
    dropdownIndicator: styles => ({
        ...styles,
        color: "#161413",
    }),
    indicatorsContainer: styles => ({
        ...styles,
        color: "#161413",
        cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    placeholder: defaultStyles => {
        return {
            ...defaultStyles,
            color: "#161413",
            marginLeft: 0,
        }
    },
  }
  // Dropdown react select styles

  return (
    <>
    <section className="teams-listing-wrapper news-listing-wrapper">
      <Container>
        <Row>
          <Col>
          <div className="teams-list-catetory">
              {mobileonly?
                <Select options={selectvalues} values={[]} isSearchable={false}
                  classNamePrefix={"select-opt"}
                  className="team-list-select-control"
                  defaultValue={{ label: selectvalues.length > 0 && selectvalues[0].label, value: selectvalues.length > 0 && selectvalues[0].value }}
                  onChange={e => setNewscat(e.value)}
                  styles={customStyles}
                  components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                />                       
                :
                <ul className="teams-list list-inline">
                  <li className="list-inline-item"><a href="javascript:void(0);" onClick={()=> setNewscat('')}className={!newscategory?'active':""}>All</a></li>
                    {data?.blogsCategories && data?.blogsCategories.map(newscat => {
                        return (
                          <li className="list-inline-item"><a href="javascript:void(0);" className={newscat?.id == newscategory?'active':""} onClick={()=> setNewscat(newscat?.id)}>{newscat?.category}</a></li>
                        )
                    })}
                </ul>
              }
            </div>
          </Col>
        </Row>
        <Row>
          {
            currentItems && currentItems?.map(newsitem => (
              <Col lg={4} md={6} xs={6} className="news-list-item">
                <div className="teamlist-card-wrap">
                  <Link to={newsitem.slug + "/"} className={"img-zoom"}>
                    <img
                      src={newsitem.tile_image.url}
                      alt=""
                      className="teamlist-img"
                    />
                  </Link>
                  <h4 className="short-description">{newsitem?.blogs_categories? newsitem?.blogs_categories[0]?.category:""}</h4>
                  <Link to={newsitem.slug + "/"} className="name-section">
                    <p className="name">{newsitem.title}</p>
                    {/* <i className="icon page-arrow-next"></i> */}
                  </Link>
                </div>
              </Col>
            ))
          }
        </Row>
        <Row>
          <Col>
            {pageCount > 1 && <ReactPaginate
              breakLabel="..."
              nextLabel={<img src={rightIcon}></img>}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<img src={leftIcon}></img>}
              renderOnZeroPageCount={null}
              className="pagination"
              // onClick={handleOnClick}
            />}
          </Col>
        </Row>
      </Container>
    </section>
    </>
  )
}

export default NewsListing
