import React from "react"
import { Link } from "gatsby"
import Select from "react-select"
import ValuationForm from "../forms/valuation"
const ValuationPageForm = () => {
  return (
    <ValuationForm />
  )
}

export default ValuationPageForm
