import React from "react"
import "./StaticImageWithText.scss"
import parse from "html-react-parser"
import GetGatsbyImage from "../common/site/get-gatsby-image"

const StaticImageWithText = ({ module }) => {
  return (
    <div className="static-image-text-wrap static-container">
      {module.media_block?.media_file && (
        <GetGatsbyImage
          image={module.media_block.media_file}
          fallbackalt={module.text_block?.title || ""}
          className="static-img"
        />
      )}
      {/* <img
        src={module?.media_block?.media_file?.url}
        alt="image"
        className="static-img"
      /> */}
      <div className="description">
        {module?.text_block?.description &&
          parse(module.text_block?.description)}
      </div>
    </div>
  )
}

export default StaticImageWithText
