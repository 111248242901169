import { Link } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container } from "react-bootstrap"
import Breadcrumbs from "../BreadCrumbs/BreadCrumbs"
import GetGGFXImage from "../common/site/get-ggfx-image"
import {
  GetPageURL,
  PageBreadcrumbs,
  scrollToElement,
} from "../common/site/utils"
import JobApplyModal from "../EnquireModal/JobApplyLandingModal"
import "./Banner.scss"
function Banner(props) {
  const banner = props.banner
  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.banner_image_Transforms) {
    processedImages = props.imagetransforms.banner_image_Transforms
  }
  const [modalShow, setModalShow] = useState(false)
  return (
    <>
      <div className="banner-section">
        <Breadcrumbs breadcrumbs={PageBreadcrumbs(props.slug)} />
        <div className="img-wrap">
          <GetGGFXImage
            imagename={"pages.banner_image.bannerimg"}
            imagesource={banner?.banner_image}
            fallbackalt={banner?.title}
            imagetransformresult={processedImages}
            id={props?.id}
          />
          <div className="overlay-bg"></div>
        </div>
        <div className="banner-content-wrap">
          <Container>
            <div className="banner-content">
              <Col lg={9} md={9}>
                <h1>{banner?.title}</h1>
                {banner.banner_content && parse(banner.banner_content)}

                {banner.show_reviews && (
                  <div className="reviews-section">
                    <i className="icon icon-google-reviews"></i>
                    <Link
                      to={"/about/customer-review/"}
                      className="review-text"
                    >
                      4.9/5 from 148 Reviews
                    </Link>
                  </div>
                )}

                {(banner.button_cta_1 || banner.button_cta_2) && (
                  <div className="banner-ctas d-flex align-items-center">
                    {banner.button_cta_1 &&
                      banner?.button_cta_1?.custom_link != "#job-openings" && (
                        <Link
                          to={GetPageURL(
                            banner.button_cta_1.menu_item,
                            banner.button_cta_1.custom_link
                          )}
                          className="btn btn-transparent-blur"
                        >
                          {banner.button_cta_1?.label}
                        </Link>
                      )}
                    {banner.button_cta_2 &&
                      banner?.button_cta_2?.custom_link !=
                        "open-job-apply-form" && (
                        <Link
                          to={GetPageURL(
                            banner.button_cta_2.menu_item,
                            banner.button_cta_2.custom_link
                          )}
                          className="btn btn-transparent-blur"
                        >
                          {banner.button_cta_2?.label}
                        </Link>
                      )}

                    {banner.button_cta_1 &&
                      banner?.button_cta_1?.custom_link === "#job-openings" && (
                        <a
                          href="javascript:;"
                          onClick={() => {
                            const element = document.getElementById(
                              banner?.button_cta_1?.custom_link.replace("#", "")
                            )
                            if (element) {
                              scrollToElement(element)
                            }
                          }}
                          className="btn btn-transparent-blur"
                        >
                          {banner.button_cta_1?.label}
                        </a>
                      )}
                    {banner.button_cta_2 &&
                      banner?.button_cta_2?.custom_link ===
                        "open-job-apply-form" && (
                        <a
                          href="javascript:;"
                          onClick={() => setModalShow(true)}
                          className="btn btn-transparent-blur"
                        >
                          {banner.button_cta_2?.label}
                        </a>
                      )}
                  </div>
                )}
              </Col>
            </div>
            <JobApplyModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Container>
        </div>
      </div>
    </>
  )
}

export default Banner
