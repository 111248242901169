import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { graphql, Link, useStaticQuery } from "gatsby"
import PlayVideo from "../PlayVideo/PlayVideo";
import './assets/styles/_index.scss';
import Img1 from "../../images/testimonial-img.png";
import Select from 'react-select';
import GetGatsbyImage from '../common/site/get-gatsby-image';

const TestimonialCategorySlider = (props) => {

    
    const data = useStaticQuery(graphql`
    query GetTestimonailsVideos {
      glstrapi {
        videoCategories {
            Name
            _id
        }

        videos {
              Title
              Content
              Video_Image {
                alternativeText
                url
                url_sharp {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        formats: WEBP
                        width: 362
                        height: 350                        
                        layout: FIXED
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )
                    }
                  }
              }
              Video_URL
              select_video_category {
                Name
              }
            }        
      }
    }
  `)

    const [videocategory, setVideocategory] = useState('interviews');
    const [videos, setVideos] = useState(data.glstrapi.videos);
    const videocat =  data.glstrapi.videoCategories;
    const [isPlay, setPlay] = useState(false);
    const [videourl, setVideourl] = useState(false);
    const [videotour, setVideoTour] = useState(false);

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: videos.length > 2 ? true : false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: videos.length > 2 ? true : false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: videos.length > 2 ? true : false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 400,
                infinite: videos.length > 2 ? true : false,
                arrows: false,
                dots: true,
                centerMode: true,
                centerPadding: '20%',
                },
            },
        ],
    }
    // Slider settings

    useEffect(() => {
        setVideos(data.glstrapi.videos?.filter(obj => obj.select_video_category?.Name === videocategory));
      }, [videocategory])

  var selectvalues = []
  {data && data.glstrapi.videoCategories && data.glstrapi.videoCategories.map(val =>selectvalues.push({ value:val.Name, label: val.Name } ))}  
  // Dropdown react select styles
const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
        ...styles,
        backgroundColor: isSelected ? "#161413" : null,
        color: isSelected ? "#ffffff" : "#161413",
    }
    },
    control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    paddingLeft: 0,
    outline: 0,
    boxShadow: "none",
    color: "#fff",
    fontSize: "1rem",
    }),
    valueContainer: styles => ({
    ...styles,
    fontSize: "1rem",
    paddingLeft: 0,
    lineHeight: "30px",
    cursor: "pointer",
    }),
    dropdownIndicator: styles => ({
    ...styles,
    color: "#fff",
    }),
    indicatorsContainer: styles => ({
    ...styles,
    color: "#fff",
    cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    placeholder: defaultStyles => {
    return {
        ...defaultStyles,
        color: "#fff",
        marginLeft: 0,
    }
    },
  }
  // Dropdown react select styles  
    return (
        <section className={`testimonial-slider-wrapper section-p dark-bg testimonials-filter-slider`}>
            <Container>
                <Row>
                    <Col>
                        {/* <h4>{props.title}</h4> */}
                        <div className="d-flex justify-content-between align-items-center testimonial-header-wrapper">
                            <h2>{props.title}</h2>
                            <div className="d-md-flex d-none align-items-center google-reviews">
                                <i className="icon icon-google-reviews"></i>
                                <div className="review-text d-flex align-items-center">4.95/5 <span className="d-lg-flex d-none">from 148 Reviews</span></div>
                            </div>
                        </div>
                        <div className='video-cat d-none d-lg-flex'>
                        {videocat.map(item => (
                            <li className={videocategory == item.Name ? 'active' : ""}><a href="javascript:;" onClick={() => setVideocategory(item.Name)}>{item.Name}</a></li>
                        ))}
                        </div>
                        <div className='video-select mobile-dropdown d-lg-none'>
                        <Select options={selectvalues} values={[]} isSearchable={false}
                        // menuIsOpen={true}
                        classNamePrefix={"select-opt"}
                        className="select-control"
                        defaultValue={{ label: selectvalues.length > 0 && selectvalues[0].label, value: selectvalues.length > 0 && selectvalues[0].value }}
                        styles={customStyles}
                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                        onChange={e => setVideocategory(e.value)}
                        
                        />
                        </div>
                    </Col>
                    
                </Row>
                <Row>
                    <Col>
                        <Slider className="testimonial-slider" {...settings}>
                        {videos.map(item => (
                            <div className="testimonial-slide">
                                <div className="testimonial-play-wrapper">
                                    {item.Video_URL &&
                                        <a onClick={(e) => { setPlay(true); setVideourl(item?.Video_URL); setVideoTour(false);}}>
                                            <div className="testimonial-play d-flex align-items-center justify-content-center"><i className="icon icon-play"></i></div>
                                        </a>
                                    }
                                </div>
                                <div className="testimonial-img-zoom">
                                    <Link to={"#"}>
                                        {/* <img src={Img1} className="img-fluid" /> */}
                                        <GetGatsbyImage
                                                image={item?.Video_Image}
                                                fallbackalt={item.Title}
                                                className="img-fluid"
                                            />
                                    </Link>
                                </div>
                                <div className="testimonial-text-wrapper">
                                    <Link to={"#"} className="link-text">{item.Title}</Link>
                                    <div className="testimonial-text">{item.Content}</div>
                                </div>
                            </div>
                        ))}
                            {/* <div className="testimonial-slide">
                                <div className="testimonial-play-wrapper">
                                    <a onClick={(e) => { setPlay(true); setVideourl("https://youtu.be/2Gg6Seob5Mg"); setVideoTour(false);}}>
                                        <div className="testimonial-play d-flex align-items-center justify-content-center"><i className="icon icon-play"></i></div>
                                    </a>
                                </div>
                                <div className="testimonial-img-zoom">
                                    <Link to={"#"}>
                                        <img src={Img1} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="testimonial-text-wrapper">
                                    <Link to={"#"} className="link-text">Miss Hubbard</Link>
                                    <div className="testimonial-text">Property Management</div>
                                </div>
                            </div>
                            <div className="testimonial-slide">
                                <div className="testimonial-play-wrapper">
                                    <a onClick={(e) => { setPlay(true); setVideourl("https://youtu.be/2Gg6Seob5Mg"); setVideoTour(false);}}>
                                        <div className="testimonial-play d-flex align-items-center justify-content-center"><i className="icon icon-play"></i></div>
                                    </a>
                                </div>
                                <div className="testimonial-img-zoom">
                                    <Link to={"#"}>
                                        <img src={Img1} className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="testimonial-text-wrapper">
                                    <Link to={"#"} className="link-text">Mr Jamie Smith</Link>
                                    <div className="testimonial-text">Buyer</div>
                                </div>
                            </div> */}
                        </Slider>

                        {/* <div className="d-xl-none d-lg-none d-md-none d-flex align-items-center google-reviews reviews-bottom">
                            <i className="icon icon-google-reviews"></i>
                            <div className="review-text d-flex align-items-center">4.95/5 from 148 Reviews</div>
                        </div> */}
                    </Col>
                </Row>
            </Container>
            {isPlay && videourl &&
                <PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="" isCloseFunction={setPlay} videourl={videourl} htmlink={""}/>
            }
        </section>
    )
}

export default TestimonialCategorySlider