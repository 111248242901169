import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import './CareersListing.scss'
import { graphql, Link, useStaticQuery } from "gatsby"
import Select from 'react-select';
const CareersListing = (props) => {
  const [jobdept, setJobdept] = useState('all');
  const data = useStaticQuery(graphql`
    query GetCareersData {
      glstrapi {
        careerDepartments {
            department
        }
        careers {
            title
            url
            career_department {
              department
            }
        }
      }
    }
  `)
  const careersdept = data.glstrapi.careerDepartments;
  const [careers, setCareers] = useState(data.glstrapi.careers);

  useEffect(() => {
    if (jobdept != "all") {
      setCareers(data.glstrapi.careers?.filter(obj => obj.career_department.department === jobdept));
    } else {
      setCareers(data.glstrapi.careers);
    }
  }, [jobdept])

  var selectvalues = []
  selectvalues.push({ value:'all', label: 'All Jobs' } )
  {data && data.glstrapi.careerDepartments && data.glstrapi.careerDepartments.map(val =>selectvalues.push({ value:val.department, label: val.department } ))}    
// Dropdown react select styles
const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  return {
      ...styles,
      backgroundColor: isFocused ? "#161413" : null,
      color: isFocused ? "#ffffff" : "#161413",
  }
  },
  control: styles => ({
  ...styles,
  backgroundColor: null,
  border: 0,
  paddingLeft: 0,
  outline: 0,
  boxShadow: "none",
  color: "#161413",
  fontSize: "1rem",
  }),
  valueContainer: styles => ({
  ...styles,
  fontSize: "1rem",
  paddingLeft: 0,
  lineHeight: "30px",
  cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
  ...styles,
  color: "#161413",
  }),
  indicatorsContainer: styles => ({
  ...styles,
  color: "#161413",
  cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
  return {
      ...defaultStyles,
      color: "#161413",
      marginLeft: 0,
  }
  },
}
// Dropdown react select styles

  return (
    <section className='section-p carrers-listing' id="job-openings">
      <Container>
        <h2>{props.title}</h2>
        <div className='carrers-dept d-none d-lg-flex'>
          <li className={jobdept == 'all' ? 'active' : ""}><a href="javascript:;" onClick={() => setJobdept('all')}>All Jobs</a></li>
          {careersdept.map(item => (
            <li className={jobdept == item.department ? 'active' : ""}><a href="javascript:;" onClick={() => setJobdept(item.department)}>{item.department}</a></li>
          ))}
        </div>
        <div className='job-select mobile-dropdown d-lg-none'>
        <Select options={selectvalues} values={[]} isSearchable={false}
          classNamePrefix={"select-opt"}
          className="select-control"
          defaultValue={{ label: selectvalues.length > 0 && selectvalues[0].label, value: selectvalues.length > 0 && selectvalues[0].value }}
          styles={customStyles}
          components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
          onChange={e => setJobdept(e.value)}
          
        />
        </div>
        <div className='opening-list'>
          {careers.map(item => (
            <div className='list-wrap'>
              <div className='list-tl'>{item.title}</div>
              <div className='list-dt'>{item?.career_department?.department}</div>
              <Link to={item?.url + "/"} className='apply-job'>Details & Apply</Link>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default CareersListing