import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import "./AreaguideListing.scss"
import ReactPaginate from "react-paginate"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import leftIcon from "../../images/icons/CaretLeft.svg"
import rightIcon from "../../images/icons/CaretRight.svg"

const AreaguideListing = () => {
  const data = useStaticQuery(graphql`
    query GetAreaguides {
      glstrapi {
        areaGuides {
          tile_image {
            url
          }
          publish
          name
          slug
          imagetransforms
          shortDescription
        }
      }
    }
  `)

  const [items, setItems] = useState([0, 1, 2])
  const [parent] = useAutoAnimate(/* optional config */)

  const areaguides = data.glstrapi.areaGuides

  const itemsPerPage = 12

  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    setCurrentItems(areaguides.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(areaguides.length / itemsPerPage))
  }, [itemOffset, itemsPerPage])

  const handlePageClick = event => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    const newOffset = (event.selected * itemsPerPage) % areaguides.length
    setItemOffset(newOffset)
  }

  return (
    <Container className="areaguide-listing-wrap">
      <div className="areaguide-listing-container" ref={parent}>
        {currentItems?.map(areaguide => (
          <div className="areaguide-card-wrap">
            <Link to={`living-in-${areaguide.slug}/`} className={"img-zoom"}>
              <img
                src={areaguide.tile_image.url}
                alt={areaguide.name + " Om-Home"}
                className="areaguide-img"
              />
            </Link>
            <Link to={`living-in-${areaguide.slug}/`} className="name-section">
              <p className="name">{areaguide.name}</p>
              <i className="icon page-arrow-next"></i>
            </Link>
            <p className="short-description">{areaguide.shortDescription}</p>
          </div>
        ))}
      </div>
      {pageCount > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={<img src={rightIcon}></img>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={<img src={leftIcon}></img>}
          renderOnZeroPageCount={null}
          className="pagination"
          // onClick={handleOnClick}
        />
      )}
    </Container>
  )
}

export default AreaguideListing
