import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import parse from "html-react-parser"
import "./GetInTouchBlock.scss"
import { GetPageURL } from "../common/site/utils"
import tileImg from "../../images/Get_in_touch.png"

const GetInTouchBlock = ({ module }) => {
  return (
    <div className={`get-in-touch-module-wrapper dark-bg section-p`}>
      <Container className={`get-in-touch-container img-right`}>
        <div className="img-block rounded">
          <img src={tileImg} alt="" />
        </div>
        <div className="content">
          <h4 className="title">get in touch</h4>
          <div className="description">
            <h2 className="">Request a call back from one of our experts</h2>
          </div>
          <div className="form-section">
            <div className="input-box">
              <label htmlFor="name">Full Name: *</label>
              <input type="text" placeholder="Full Name" id="name" />
            </div>
            <div className="input-box">
              <label htmlFor="name">Phone: *</label>
              <input type="text" placeholder="Phone Number" id="name" />
            </div>
            <div className="input-box">
              <label htmlFor="name">Email Address:</label>
              <input type="text" placeholder="Email Address" id="name" />
            </div>
            <Link to={"/contact-omhome/"} className="btn btn-grey">
              Get in touch
            </Link>
          </div>
          {/* {module.Button_CTA && (
            <Link
              target={module.Button_CTA.target === "new_window" ? "_blank" : ""}
              to={GetPageURL(module.Button_CTA?.menu_item)}
              className="btn btn-grey"
            >
              {module.Button_CTA.label}
            </Link>
          )} */}
        </div>
      </Container>
    </div>
  )
}

export default GetInTouchBlock
