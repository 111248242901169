import React from "react"
import { Container } from "react-bootstrap"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import parse from "html-react-parser"
import ContactForm from "../components/ContactForm/ContactForm"
import ValuationPageForm from "../components/ContactForm/ValuationForm"
import MortgageForm from "../components/MortgageForm/MortgageForm"
import RentalYieldForm from "../components/MortgageForm/RentalYieldForm"
import BookingForm from "../components/forms/book-a-viewing"
import ShareFrndFormPage from "../components/forms/sendfrnd-form"
import { useLocation } from "@reach/router"
import { parse as Queryparse } from "query-string"
import { GetPropertyBooking } from "../queries/common_use_query"
import { PageBreadcrumbs } from "../components/common/site/utils"
import Breadcrumbs from "../components/BreadCrumbs/BreadCrumbs"
import ContactPageForm from "../components/forms/contact"
import GetGatsbyImage from "../components/common/site/get-gatsby-image"
import GetGGFXImage from "../components/common/site/get-ggfx-image"

const FormTemplate = props => {
  const GQLPage = props.GQLPage

  const GQLModules = props.GQLModules
  const bannerSection = GQLPage.banner_section
  const locationState = props.locationState

  const filteredModules = GQLModules.filter(
    module => module.__typename === "GLSTRAPI_ComponentComponentsStaticContent"
  )

  const staticData = filteredModules && filteredModules[0]

  // Booking Property
  const location = useLocation()
  const searchParams = Queryparse(location.search) // => {init: "true"}
  const pid = searchParams.pid
  const {
    loading: prop_loading,
    error: prop_error,
    data: prop_data,
  } = GetPropertyBooking(pid)

  var propertyurl = ""
  var backurl = ""
  var propertyslug = ""
  var searchtype =
    prop_data && prop_data.properties.length > 0
      ? prop_data.properties[0].search_type
      : ""
  var displayaddress =
    prop_data && prop_data.properties.length > 0
      ? prop_data.properties[0].display_address
      : ""
  if (prop_data && prop_data.properties.length > 0) {
    propertyurl =
      searchtype == "sales" ? "/property-for-sale/" : "/property-for-rent/"
    backurl =
      searchtype == "sales"
        ? "/property-for-sale/in-dubai"
        : "/property-for-rent/in-dubai"
    propertyslug =
      prop_data.properties[0].slug + "-" + prop_data.properties[0].id + "/"
  }
  //

  const title = locationState?.teamName
    ? `${bannerSection.title} ${locationState?.teamName}`
    : bannerSection.title
  const toEmail = locationState?.teamEmail ? locationState?.teamEmail : ""

  let processedImages = JSON.stringify({})
  if (GQLPage?.imagetransforms?.banner_image_Transforms) {
    processedImages = GQLPage.imagetransforms.banner_image_Transforms
  }

  return (
    <div
      className={`form-template-wrapper ${
        GQLPage.page_class ? GQLPage.page_class : ""
      }`}
    >
      <div className="form-template-container">
        <Header layout={GQLPage.layout} noFixed />
        {/* <GetGatsbyImage
          image={bannerSection.banner_image}
          fallbackalt="banner-img"
          className="banner-bg-img"
        /> */}
        <GetGGFXImage
          imagename={"pages.banner_image.bannerimg"}
          imagesource={bannerSection.banner_image}
          fallbackalt={bannerSection?.title}
          imagetransformresult={processedImages}
          id={GQLPage?.id}
          className="banner-bg-img"
        />
        <div className="overlay"></div>
        <Breadcrumbs breadcrumbs={PageBreadcrumbs(GQLPage.menu.slug)} />

        <Container className="form-section-wrap">
          <div className="form-section-container">
            <div className="left-section">
              <div className="content">
                <h1 className="title">{title}</h1>
                {bannerSection.banner_content && (
                  <div className="description">
                    {parse(bannerSection.banner_content)}
                  </div>
                )}
                <div className="form-section">
                  {GQLModules.map(module => (
                    <>
                      {module.__typename ===
                        "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
                        module.Select_Module === "Contact_Page" && (
                          <ContactPageForm to_email_id={toEmail} />
                        )}
                      {module.__typename ===
                        "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
                        module.Select_Module === "Valuation_Form" && (
                          <ValuationPageForm />
                        )}
                      {module.__typename ===
                        "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
                        module.Select_Module === "Mortgage_Calculator" && (
                          <MortgageForm price="3000000" />
                        )}
                      {module.__typename ===
                        "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
                        module.Select_Module === "Rental_Yield_Calculator" && (
                          <RentalYieldForm />
                        )}

                      {module.__typename ===
                        "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
                        module.Select_Module === "Booking_Form" &&
                        prop_data &&
                        prop_data.properties.length > 0 && (
                          <BookingForm
                            property_url={
                              location.origin + propertyurl + propertyslug
                            }
                            property_id={prop_data.properties[0].id}
                            page_url={
                              location.origin + propertyurl + propertyslug
                            }
                            property_title={displayaddress}
                            property_img={
                              prop_data.properties[0].images &&
                              prop_data.properties[0].images[0].url
                            }
                            property_address={displayaddress}
                            property_bedrooms={prop_data.properties[0].bedroom}
                            property_bathrooms={
                              prop_data.properties[0].bathroom
                            }
                          />
                        )}
                      {module.__typename ===
                        "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
                        module.Select_Module === "SendFriend_Form" &&
                        prop_data &&
                        prop_data.properties.length > 0 && (
                          <ShareFrndFormPage
                            property_url={
                              location.origin + propertyurl + propertyslug
                            }
                            property_id={prop_data.properties[0].id}
                            page_url={
                              location.origin + propertyurl + propertyslug
                            }
                            property_title={displayaddress}
                            property_img={
                              prop_data.properties[0].images &&
                              prop_data.properties[0].images[0].url
                            }
                            property_address={displayaddress}
                            property_bedrooms={prop_data.properties[0].bedroom}
                            property_bathrooms={
                              prop_data.properties[0].bathroom
                            }
                          />
                        )}
                    </>
                  ))}
                </div>
              </div>
            </div>

            {staticData && (
              <div className="right-section">
                {staticData.Content && parse(staticData.Content)}
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  )
}

export default FormTemplate
