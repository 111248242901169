import React from "react"
import parse from "html-react-parser"
import "./StaticRichTextBlock.scss"

const StaticRichTextBlock = ({ module }) => {
  return (
    <div className="static-text-block-wrap static-container">
      <div className="content">{module?.content && parse(module?.content)}</div>
    </div>
  )
}

export default StaticRichTextBlock
