import React from "react"
import "./BannerStatic.scss"
import Breadcrumbs from "../BreadCrumbs/DetailsBreadCrumbs"
import {
  GetPageURL,
  PageBreadcrumbs,
  scrollToElement,
} from "../common/site/utils"
import parse from "html-react-parser"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import fbLogo from "../../images/icons/round-fb.svg"
import twiiterLogo from "../../images/icons/round-twitter.svg"
import linkedInLogo from "../../images/icons/round-in.svg"
import { useLocation } from "react-use"

const BannerStatic = ({ banner, id, slug,page_class }) => {
  const urlState = useLocation()

  const shareURL = urlState?.href
  const host = urlState?.host

  const slugArray = ['dubai-property-news', 'our-team', 'dubai-communities'];
  let noBreadcrumb = slugArray.includes(slug)

  return (
    <div className={`static-banner-wrapper ${page_class}`}>
      {noBreadcrumb ? '' : <Breadcrumbs breadcrumbs={PageBreadcrumbs(slug)} />}
      <div className="static-banner-container static-container">
        <h1 className="heading">{banner.title}</h1>
        <div className="content">
          {banner.banner_content && parse(banner.banner_content)}
        </div>
        {banner.show_share && (
          <div className="cta-section">
            <FacebookShareButton
              quote={banner.title}
              url={shareURL}
              className="fb-logo"
            >
              <img src={fbLogo} alt="fb-logo" />
            </FacebookShareButton>
            <TwitterShareButton
              title={banner.title}
              url={shareURL}
              className="twitter-logo"
            >
              <img src={twiiterLogo} alt="twiiter-logo" />
            </TwitterShareButton>
            <LinkedinShareButton
              title={banner.title}
              url={shareURL}
              source={host}
              className="linkedin-logo"
            >
              <img src={linkedInLogo} alt="linkedin-logo" />
            </LinkedinShareButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default BannerStatic
