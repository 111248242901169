import { Link } from "gatsby"
import React, { useState } from "react"
import EnquireModal from "../EnquireModal/EnquireModal"
import "./StaticBookValuation.scss"

const StaticBookValuation = ({ isModal }) => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
    <div className="static-valuation-wrap static-container">
      <div className="static-valuation-container">
        <div className="content">
          <h2 className="title">Get a selection for living and investing</h2>
          {/* <p className="description">
            Book a free valuation with one of our experts.
          </p> */}
        </div>
        {isModal ?
           <a href="javascript:;" onClick={() => setModalShow(true)} className="btn btn-white">find out more</a>
        : <Link to="/property-valuation-in-dubai/" className="btn btn-white">find out more</Link>
        }
      </div>
    </div>
    <EnquireModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default StaticBookValuation
