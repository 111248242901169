import React from "react"
import "./IconGridModule.scss"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"

const IconGridModule = ({ module }) => {

  return (
    <div
      className={`icon-grid-wrapper ${
        module.Title ? "section-p" : "icon-grid-wrapper-white section-m"
      }`}
    >
      <Container className="icon-grid-container">
        <div className="content">
          {module.Title && <h4 className="title">{module.Title}</h4>}
          <h2 className="heading">{module.Heading}</h2>
        </div>
        <div className="icons-section-wrapper">
          {module.Icon_Items?.length > 0 &&
            module.Icon_Items.map(item => (
              <div className="icon-section-wrap">
                <i className={`icon icon-${item.Icon}`}></i>
                <div className="icon-content">
                  <p className="title">{item.Title}</p>
                  {item.content && (
                    <div className="description">{parse(item.content)}</div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Container>
    </div>
  )
}

export default IconGridModule
