import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { GetPageURL } from "../common/site/utils"
import "./FullWidthBanner.scss"
import NewsletterImg from "./newsletter-banner.png"
import NewsletterForm from "../forms/newsletter-form"
import { StaticImage } from "gatsby-plugin-image"

const NewsletterBanner = ({ module }) => {

  return (
    <div className="full-width-banner-wrapper">
      <StaticImage
        src={"./newsletter-banner.png"}
        alt="full-width-banner-img"
        className="newsletter-form-img banner-img"
      />
      {/* <div className="overlay"></div> */}
      <Container className="banner-container full-width-banner-container">
        <h4 className="title">{module?.Title ? module?.Title : "subscribe to our newsletter"}</h4>
        <h2 className="heading">{module?.Description ? module?.Description : "Stay up to date with everything at om home"}</h2>
        <div className="newsletter-form">
          <NewsletterForm />
          <div className="newsletter-terms">
            <p>
              By clicking Submit you agree to our{" "}
              <Link to="/terms-and-conditions">Terms</Link> and{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
            <p className="required">* required field</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NewsletterBanner
